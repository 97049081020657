import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotifRestcService extends ARestClient{
  

  constructor(httpClient: HttpClient) { 
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msNotif+"/";
  }

  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths{
    let restPaths =super.getRestPaths();
    restPaths.add ="create";
    return restPaths;
  }
}
