import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable, Subject } from 'rxjs';
import { EmpleadoCatalog, Empleado } from 'src/app/api/empleado';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { ContratoPsService } from 'src/app/procs/empleado/contrato-ps.service';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoPsService extends BaseProcess<EmpleadoRestcService>{


  constructor(httpClient: HttpClient, protected restc: EmpleadoRestcService,
    private contratops: ContratoPsService) {
    super(httpClient, restc);
  }

  /**
   * 
   * @returns Temporalmente mientras se desarrolla el servicio del catálogo de empleados.
   */
  find<Empleado, EmpleadoCatalog>(): Observable<EmpleadoCatalog[]> {
    let cat = new Subject<EmpleadoCatalog[]>();
    this.contratops.all().subscribe((data)=>{
      if(data){
        this.restc.getNombres(data as number[]).subscribe((emps) => {
          cat.next(emps);
        });
      }
    })


    /* let cat = new Subject<EmpleadoCatalog[]>();
    super.find({}).subscribe(
      (data) => {

        let emps = data as Empleado[];
        let cemps: EmpleadoCatalog[] = [];
        emps.forEach((emp) => {
          if (emp.hasOwnProperty("personales")) {
            let cemp = {} as EmpleadoCatalog;
            cemp["id"] = emp["id"];
            cemp["nombreCompleto"] = emp["personales"].nombre + " " + emp["personales"].apellidoPaterno + " " + emp["personales"].apellidoMaterno
            cemps.push(cemp);
          }

        })
        cat.next(cemps);
      }
    ); */

    return cat;
  }
}
