import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable } from 'rxjs';
import { EmpleadoCatalog } from 'src/app/api/empleado';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msEmpleado + "/";
  }
  processBody(res: any) {
    return res || {};
  }


  getNombres(idsEmpleado: number[]): Observable<any> {
    let path = this.getBaseEndpoint() + "ps/nombre-empleados";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, idsEmpleado, { headers }) as Observable<EmpleadoCatalog>;
  }









}
