<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<div class="p-grid axks-notif" *ngIf="loadContent">
    <div class="p-col-12 p-md-12 axks-notif-btn-tipo">
        <h3>Envía notificación a {{(item.esGrupo ? 'Grupo':'Empleado')}}</h3>
        <p-toggleButton [(ngModel)]="item.esGrupo" onLabel="Envío a empleado" offLabel="Envío a grupo"
            onIcon="pi pi-users" offIcon="pi pi-user" (onChange)="onChangeTipo($event)"></p-toggleButton>
    </div>
    <div class="p-md-6 p-col-12 axks-notif-tipo" *ngIf="item.esGrupo">
        <br><br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-gp-notif" [(ngModel)]="item.idDestinatario" [options]="grupos"
                optionLabel="grupoNotif" optionValue="idGrupoNotif" [autoDisplayFirst]="false" autoWidth="false"
                [style]="{'width':'95%'}">
            </p-dropdown>
            <label for="fl-gp-notif">Grupos</label>
        </span>
    </div>
    <div class="p-md-6 p-col-12 axks-notif-tipo" *ngIf="!item.esGrupo">
        <br><br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-emp" [(ngModel)]="item.idDestinatario" [options]="empleados"
                optionLabel="nombreCompleto" optionValue="id" [autoDisplayFirst]="false" autoWidth="false"
                [style]="{'width':'95%'}" [filter]="true" filterBy="nombreCompleto">
            </p-dropdown>
            <label for="fl-emp">Empleados</label>
        </span>
    </div>
    <div class="p-md-6 p-col-12 axks-notif-mess">
        <textarea pInputTextarea [rows]="4" [(ngModel)]="item.mensaje" [style]="{'width':'95%'}"
            autoResize="true"></textarea>
    </div>
    <div class="p-md-6 p-col-12 axks-notif-btns">
        <p-button label="Enviar" (onClick)="enviar($event)"></p-button>
    </div>
</div>