import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './views/home/home.component';
import { MessageService } from 'primeng/api';
import { ActionsBarModule, BaseMessages, DetailTabModule, OperationsBarModule, ResultListModule, SessionClientProvider } from '@axks/components';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { NotifComponent } from './views/notif/notif/notif.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotifComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ActionsBarModule,
    OperationsBarModule,
    ResultListModule,
    DetailTabModule,
    ToastModule,
    CardModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressSpinnerModule
  ],
  providers: [MessageService,BaseMessages,SessionClientProvider,
    { provide: LOCALE_ID, useValue: 'es-MX'},
    {provide:'env', useValue:environment},],
  bootstrap: [AppComponent]
})
export class AppModule { }
