import { Component, OnInit } from '@angular/core';
import { GrupoNotifPsService } from 'src/app/procs/grupo-notif/grupo-notif-ps.service';
import { GrupoNotif } from 'src/app/api/grupo-notif';
import { Notificacion } from 'src/app/api/notificacion';
import { EmpleadoPsService } from 'src/app/procs/empleado/empleado-ps.service'
import { Empleado, EmpleadoCatalog } from 'src/app/api/empleado';
import { NotifPsService } from 'src/app/procs/notif/notif-ps.service';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { ProfileC } from '@axks/components/lib/api/views/profile';

@Component({
  selector: 'axks-notif',
  templateUrl: './notif.component.html',
  styleUrls: ['./notif.component.scss']
})
export class NotifComponent extends BaseView implements OnInit {

  grupos: GrupoNotif[];
  item: Notificacion;
  empleados: EmpleadoCatalog[];

  constructor(
    protected screenModeService: OperationScreenModeService, 
    protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected sessionProvier: SessionClientProvider,
    private grupoNotifPs: GrupoNotifPsService,
    private empleadoPs: EmpleadoPsService,
    private notifPs: NotifPsService) { 
      super(screenModeService,deviceService,messageService,sessionProvier);
      console.log("En notif")
    }

  ngOnInit(): void {
    this.emptyItem();
    this.initGroups();
    this.initEmpleados();
  }

  emptyItem(){
    this.item = {esProceso:false, esGrupo:false} as Notificacion;
    let prof = this.getProfile() as ProfileC;
    this.item.idRemitente = prof.idEmpleado;
    this.item.titulo = "Mensaje de "+prof.nombreEmpleado;
    let fecVig = new Date();
    fecVig.setDate(fecVig.getDate()+30);
    this.item.fechaFinVigencia = fecVig;

  }

  initGroups(){
    this.grupoNotifPs.all().subscribe(
      (data) =>{
        this.grupos = data;
      }
    );
  }

  initEmpleados(){
    this.empleadoPs.find().subscribe(
      (data) => {
        this.empleados = data as EmpleadoCatalog[];
        console.log(this.empleados)
      }
    )
  }

  enviar(event){
    this.notifPs.register(this.item).subscribe(
      (data) => {
        this.infoMessage("Envío","La notificación se envío correctamente.");
        this.emptyItem();
      }
    );
  }

  onChangeTipo(event){
    this.item.idDestinatario = undefined
  }

}
