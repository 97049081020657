import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { NotifRestcService } from 'src/app/client/notif/notif-restc.service';

@Injectable({
  providedIn: 'root'
})
export class NotifPsService extends BaseProcess<NotifRestcService>{

  constructor(httpClient: HttpClient, restc: NotifRestcService) { 
    super(httpClient, restc);
  }
}
