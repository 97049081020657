import { version } from '../../package.json';

export const environment = {
  production: false,
  dev: false,
  uat: true,
  version: version,

  appId: "tools-gui",
  empresas: [
    { id: 1, domain: 'uat.axkansoluciones.com' },
    { id: 2, domain: 'uat.sbsynergy.mx' },
    { id: 3, domain: 'uat.habitataxkan.com.mx' }
  ],

  sprofile: "https://sec.uat-ms.axkans.org/autentica/profile",
  msGrupo: "https://notif.uat-ms.axkans.org/notificaciones/grupo-notificaciones",
  msEmpleado: "https://rh.uat-ms.axkans.org/rh/empleado",
  msContratacion: "https://rh.uat-ms.axkans.org/rh/contratacion",
  msNotif: "https://notif.uat-ms.axkans.org/notificaciones/notificaciones",
  theme: {
    default: 'https://static.uat-gui.axkans.org/portal/themes/blue-gray/styles/theme.css'
  }
};