import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppClient, SessionClientProvider } from '@axks/components';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'axks-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AppClient{
  title = 'tools-gui';

  constructor(protected router: Router,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    @Inject(DOCUMENT) private docs: Document) {
    super(environment, router, messageService, sessProvider, docs);
  }

  /* init(){
    sessionStorage.clear();
    this.sessionProv.clearSession();
    super.init();
  } */
}
